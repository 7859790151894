<template>
  <v-row>
    <v-col cols="12" class="py-1">
      <v-card outlined>
        <v-container class="pa-4">
          <p class="caption ma-0">Nombre(s)</p>
          <p class="text-subtitle-1 mb-2">
            {{ personalData.first_name }}
          </p>
          <p class="caption ma-0">Appelidos</p>
          <p class="text-subtitle-1 mb-2">
            {{ personalData.last_name }}
          </p>
          <div>
            <v-row>
              <v-col cols="6" class="py-0">
                <p class="caption ma-0">Telefono</p>
                <p class="text-subtitle-1 mb-2">
                  {{ personalData.phone }}
                </p>
              </v-col>
              <v-col cols="6" class="text-right py-0">
                <p class="caption ma-0">WhatsApp</p>
                <p class="text-subtitle-1 mb-2">
                  {{ personalData.whatsapp }}
                </p>
              </v-col>
            </v-row>
          </div>
          <p class="caption ma-0">Ubicación</p>
          <p class="text-subtitle-1 mb-2">
            {{ personalData.location | locationFormat }}
          </p>
          <p class="caption ma-0">Personal UID</p>
          <p class="text-subtitle-1 mb-2">
            {{ uid }}
          </p>
          <p class="caption ma-0">ID del negocio</p>
          <p class="text-subtitle-1 mb-2">
            {{ businessID }}
          </p>
        </v-container>
      </v-card>
    </v-col>
    <v-col cols="12" class="py-1">
      <v-card height="80" outlined>
        <v-list-item class="ma-0 pa-0">
          <v-list-item-content class="ma-0 pa-0">
            <div class="pl-3 pt-2">
              <p class="ma-0 caption">Sucursal por defecto</p>
              <div
                class="text-h6 ma-0 text-truncate"
                style="marginTop:-4px !important"
              >
                <span>{{ getCurrentBranchOffice.branch_office_name }}</span>
              </div>
              <div
                class="caption ma-0 text-truncate"
                style="marginTop:-5px !important"
              >
                <v-icon size="13" class="mr-1">location_on</v-icon
                >{{ getCurrentBranchOffice.location | locationFormat }}
              </div>
            </div>
          </v-list-item-content>

          <v-icon x-large class="pa-3">store</v-icon>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("business", ["getCurrentBranchOffice"])
  },
  filters: {
    locationFormat(location) {
      if (location)
        return `${location.street_name}, ${location.city_name}, ${location.state_name}, México, ${location.postal_code}`;
      else return "";
    }
  },
  props: {
    uid: {
      type: String,
      default: "dfseasas"
    },
    businessID: {
      type: String,
      default: ""
    },
    personalData: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style></style>
