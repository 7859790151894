<template>
  <v-footer dark padless fixed>
    <v-card
      class="teal"
      flat
      tile
      width="100%"
      :style="{ marginLeft: $vuetify.breakpoint.lgAndUp ? '250px' : '0' }"
    >
      <v-container class="d-flex justify-space-between align-center">
        <p class="caption ma-0">{{ message }}</p>
        <div class="d-flex">
          <v-btn small outlined class="mr-1" @click="discardChanges"
            ><v-icon>restore</v-icon></v-btn
          >
          <v-btn small @click="saveChanges">Guardar</v-btn>
        </div>
      </v-container>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  props: {
    saveChanges: {
      type: Function
    },
    discardChanges: {
      type: Function
    },
    message: {
      type: String,
      default: "Haz realizado cambios"
    }
  }
};
</script>
