// :: This Service exports different rules to validate input fields

// :: Receives a value and tests if it is a valid email
exports.emailValid = value =>
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  ) || "Correo inválido";

// :: Receives a value and tests if it meets the minimum characters
exports.minLength = min => value =>
  value.length >= min || `Mínimo de caracteres permitido: ${min}`;

// :: Receives a value and tests if it meets the maximum characters
exports.maxLength = max => value =>
  value.length <= max || `Máximo de caracteres permitido: ${max}`;

// :: Receives a value and tests if the value is not empty
exports.required = v => !!v || "Campo requerido";

exports.onlyNumbers = value =>
  /^[0-9]*$/.test(value) || "Solo estan permitidos los numeros.";

// eslint-disable-next-line
exports.notSpecialCharacters = value => /^[A-Z0-9ñ]+$/i.test(value) || "No estan permitidos los caracteres especiales";
exports.validURL = value =>
  /^[a-zA-Z0-9_-]{1,14}$/i.test(value) || "url no valida";
