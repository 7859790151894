<template>
  <v-row>
    <v-col cols="12" class="py-1">
      <v-card outlined>
        <v-container class="pa-4">
          <h4 class="text-h6 mb-1">Ajustes</h4>
          <p class="mb-2 caption">
            Aquí puedes realizar ajustes a tu aplicación y en la forma en que
            esta interactúa con tigo.
          </p>
          <v-card outlined>
            <v-container>
              <h3 class="text-subtitle-1">Tema</h3>
              <p class="caption">
                Puedes alternar entre los diferentes temas (estos cambian el
                aspecto general de tu aplicación)
              </p>
              <div>
                <v-radio-group
                  hide-details
                  class="ma-0"
                  v-model="themeMode"
                  @change="statusChangesDetected(true)"
                >
                  <v-row>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                      <v-radio :key="0" label="Light" class="mb-0"></v-radio>
                      <v-container>
                        <v-skeleton-loader
                          class="mx-auto"
                          light
                          boilerplate
                          max-width="300"
                          type="article"
                          elevation="3"
                        ></v-skeleton-loader>
                      </v-container>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                      <v-radio :key="1" label="Dark"></v-radio>
                      <v-container>
                        <v-skeleton-loader
                          class="mx-auto"
                          dark
                          boilerplate
                          max-width="300"
                          type="article"
                          elevation="3"
                        ></v-skeleton-loader>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </div>
            </v-container>
          </v-card>
          <v-card outlined class="mt-2">
            <v-container>
              <p class="ma-0">Productos</p>
              <p class="ma-0 caption">
                Notificaciones con respecto al estatus de los productos
              </p>
              <div class="d-flex flex-wrap">
                <v-checkbox
                  hide-details
                  label="Sin inventario"
                  class="mr-2"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  label="Vendidos"
                  class="mr-2"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  label="Modificados"
                  class="mr-2"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  label="Stock"
                  class="mr-2"
                  disabled
                ></v-checkbox>
              </div>
            </v-container>
          </v-card>
          <v-card outlined class="mt-2">
            <v-container>
              <p class="ma-0">Ordenes</p>
              <p class="ma-0 caption">
                Notificaciones de las ordenes
              </p>
              <div class="d-flex flex-wrap">
                <v-checkbox
                  hide-details
                  label="Nueva orden"
                  class="mr-2"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  label="Orden cancelada"
                  class="mr-2"
                  disabled
                ></v-checkbox>
                <v-checkbox
                  hide-details
                  label="Devoluciones"
                  class="mr-2"
                  disabled
                ></v-checkbox>
              </div>
            </v-container>
          </v-card>
        </v-container>
      </v-card>
    </v-col>
    <v-scroll-y-reverse-transition leave-absolute>
      <app-save-changes
        v-if="wereChangesDetected"
        :saveChanges="saveChanges"
        :discardChanges="discardChanges"
      ></app-save-changes>
    </v-scroll-y-reverse-transition>
  </v-row>
</template>

<script>
import SaveChanges from "@/components/app/SaveChanges";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      themeMode: this.$vuetify.theme.dark ? 1 : 0
    };
  },
  computed: {
    ...mapGetters("app", ["wereChangesDetected"])
  },
  beforeDestroy() {
    this.statusChangesDetected(false);
  },
  methods: {
    ...mapActions("settings", ["changeTheme"]),
    ...mapActions("app", ["statusChangesDetected"]),
    discardChanges() {
      this.$vuetify.theme.dark == true
        ? (this.themeMode = 1)
        : (this.themeMode = 0);
      this.statusChangesDetected(false);
    },
    saveChanges() {
      if (this.themeMode === 0) {
        this.$vuetify.theme.dark = false;
        this.changeTheme(false);
      }
      if (this.themeMode === 1) {
        this.$vuetify.theme.dark = true;
        this.changeTheme(true);
      }
      this.statusChangesDetected(false);
    }
  },
  components: {
    AppSaveChanges: SaveChanges
  }
};
</script>

<style></style>
