<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Cuenta
        </h3>
      </v-col>
      <v-col cols="12" v-if="userInfoData">
        <v-slide-x-transition leave-absolute>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card
                class="pa-0 ma-0 text-right"
                dark
                color="blue-grey darken-1"
                elevation="5"
              >
                <v-chip outlined color="#d2ab7a" class="mt-2 mr-2" small
                  >Pro <v-icon right small>verified_user</v-icon></v-chip
                >
                <div class="d-flex flex-column align-center">
                  <v-avatar
                    height="100"
                    width="100"
                    color="teal"
                    class="elevation-6"
                  >
                    <v-img
                      :src="userInfoData.photoURL"
                      v-if="userInfoData.photoURL"
                    />
                    <span class="white--text headline text-h3" v-else>{{
                      userInfoData.first_name | getFirstName
                    }}</span>
                  </v-avatar>
                  <!-- <a class="caption text-decoration-none mt-2" href="#">Cambiar foto</a> -->
                  <p class="text-h5 mt-2 ma-0">
                    {{ userInfoData.first_name }}
                  </p>
                  <p class="text-subtitle-2 orange--text ma-0">
                    {{
                      data.customClaims.admin ? "Administrador" : "Colaborador"
                    }}
                  </p>
                </div>

                <v-tabs
                  v-model="tab"
                  grow
                  color="white"
                  background-color="blue-grey darken-1"
                >
                  <v-tab>
                    Detalle
                  </v-tab>
                  <v-tab>
                    Editar
                  </v-tab>
                  <v-tab>
                    <v-icon>settings</v-icon>
                  </v-tab>
                </v-tabs>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-tabs-items v-model="tab" class="background">
                <v-tab-item :key="0">
                  <app-detail
                    :personalData="userInfoData"
                    :uid="data.uid"
                    :businessID="data.business_id"
                  ></app-detail>
                </v-tab-item>
                <v-tab-item :key="1">
                  <app-edit :form="userInfoData"></app-edit>
                </v-tab-item>
                <v-tab-item :key="2">
                  <app-settings></app-settings>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-slide-x-transition>
      </v-col>

      <v-col cols="12" v-if="!userInfoData">
        <v-skeleton-loader
          type="image, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Detail from "../../components/settings/account/Detail";
import Edit from "../../components/settings/account/Edit";
import Settings from "../../components/settings/account/Settings";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      tab: null
    };
  },
  methods: {
    ...mapActions("user", ["geUserData"])
  },
  async mounted() {
    await this.geUserData();
  },
  computed: {
    ...mapState("user", ["userInfoData", "data"])
  },
  components: {
    AppDetail: Detail,
    AppEdit: Edit,
    AppSettings: Settings
  },
  filters: {
    getFirstName(string) {
      if (string) {
        const letter = string.charAt(0).toUpperCase();
        return letter.slice(0, 1);
      } else {
        return "U";
      }
    }
  }
};
</script>
