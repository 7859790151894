<template>
  <v-card outlined>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            type="text"
            ref="firstName"
            v-model="form.first_name"
            hide-details
            label="Nombre (s)"
            disabled
            @keyup.enter.native="$refs.lastName.focus()"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            type="text"
            ref="lastName"
            v-model="form.last_name"
            :rules="[requiredRule]"
            hide-details
            label="Apellidos"
            disabled
            @keyup.enter.native="$refs.mail.focus()"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            type="email"
            ref="mail"
            v-model="form.email"
            :rules="[requiredRule, emailValidRule]"
            hide-details
            label="Correo"
            disabled
            @keyup.enter.native="$refs.phoneNumber.focus()"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            type="tel"
            ref="phoneNumber"
            v-model="form.phone"
            :rules="[requiredRule]"
            hide-details
            label="Telefonos"
            disabled
            @keyup.enter.native="$refs.whatsapp.focus()"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            type="tel"
            ref="whatsapp"
            v-model="form.whatsapp"
            :rules="[requiredRule]"
            hide-details
            label="Whatsapp"
            disabled
            @keyup.enter.native="$refs.mail.focus()"
          ></v-text-field>
        </v-col>
      </v-row>
      <p class="caption text--secondary">
        Por el momento esta información no es editable
        <v-icon small color="error">error</v-icon>
      </p>
    </v-container>
  </v-card>
</template>

<script>
import { required, emailValid } from "@/services/ValidatorService";
export default {
  data() {
    return {
      requiredRule: required,
      emailValidRule: emailValid
    };
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style></style>
